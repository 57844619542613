<template>
  <div class="app-img">
    <div
      v-if="dataUrl"
      :style="{ background }"
      class="app-img__placeholder"
    >
      <img
        :src="placeholder || dataUrl"
        alt
        v-bind="$attrs"
      >
    </div>
    <img
      :src="dataUrl"
      :alt="$attrs.alt || ''"
      v-bind="$attrs"
      class="app-img__img"
    >
  </div>
</template>

<script>
// https://austingil.com/create-lazy-loading-image-component-faster-vue-js-apps/

export default {
  inheritAttrs: false,

  props: {
    src: {
      type: String,
      required: true
    },
    placeholder: String,
    background: String
  },

  computed: {
    dataUrl() {
      const { width, height } = this.$attrs;
      if (!width || !height) return "";

      // create a tiny png with matching aspect ratio as img
      const w = 100;
      const canvas = document.createElement("canvas");
      canvas.width = w;
      canvas.height = (height / width) * w;

      return canvas.toDataURL();
    }
  },

  mounted() {
    const { src, srcset, $el } = this;
    let timeOut;

    const observer = new IntersectionObserver(([entry]) => {
      const img = $el.querySelector(`.app-img__img`);
      const placeholder = $el.querySelector(`.app-img__placeholder`);

      img.onload = function () {
        delete img.onload;
        $el.classList.add(`app-img--loaded`);
        if (placeholder) {
          timeOut = setTimeout(() => {
            placeholder.remove();
          }, 300);
        }
      };
      if (entry.isIntersecting) {
        // Element is in viewport
        if (!!srcset) {
          img.srcset = srcset;
        }
        img.src = src;
        observer.disconnect();
      }
    });
    observer.observe($el);

    /*this.$once("hook:beforeDestroy", () => {
      observer.disconnect();
      if (timeOut) {
        clearTimeout(timeOut);
      }
    });*/

  }
};
</script>

<style>
.app-img {
  position: relative;
  width: 100%;
  height: 100%;
}

.app-img__img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.fade-right {
  -webkit-transform: translateX(50px);
  transform: translateX(50px);
  opacity: 0;
}

.app-img--loaded .fade-right.app-img__img {
  animation: fade-in-right 1s ease forwards 250ms;
}

.fade-left {
  -webkit-transform: translateX(-50px);
  transform: translateX(-50px);
  opacity: 0;
}

.app-img--loaded .fade-left.app-img__img {
  animation: fade-in-left 1s ease forwards 250ms;
}

.fade-in {
  opacity: 0;
}

.app-img--loaded .fade-in.app-img__img {
  animation: fade-in 1s ease forwards 250ms;
}

/**
 * ----------------------------------------
 * animation fade-in-right
 * ----------------------------------------
 */
@-webkit-keyframes fade-in-right {
  0% {
    -webkit-transform: translateX(50px);
    transform: translateX(50px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
}
@keyframes fade-in-right {
  0% {
    -webkit-transform: translateX(50px);
    transform: translateX(50px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
}

/**
 * ----------------------------------------
 * animation fade-in-left
 * ----------------------------------------
 */
@-webkit-keyframes fade-in-left {
  0% {
    -webkit-transform: translateX(-50px);
    transform: translateX(-50px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
}
@keyframes fade-in-left {
  0% {
    -webkit-transform: translateX(-50px);
    transform: translateX(-50px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
}

/**
 * ----------------------------------------
 * animation fade-in
 * ----------------------------------------
 */
@-webkit-keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
</style>