import { createApp } from 'vue'
import App from './App.vue'
import router from './router'

// Custom CSS files
import './style/reset.css';
import './style/main.css';

/* import font awesome icon component */
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import './icons.js';

createApp(App)
    .component('fa-icon', FontAwesomeIcon)
    .use(router).mount('#app')
