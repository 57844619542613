<template>
  <div class="footer">
    <footer>
      <div class="container">
        <ul class="social-links">
          <li><a
              href="https://soundcloud.com/dunright"
              target="_blank"
            >
              <fa-icon :icon="['fab', 'soundcloud']" />
            </a></li>
          <li><a
              href="https://www.instagram.com/haakon_evjen/"
              target="_blank"
            >
              <fa-icon :icon="['fab', 'instagram']" />
            </a></li>

        </ul>

      </div>
      <div class="copyright">
        <p>Utviklet av <a
            class="link"
            href="https://erlendweb.no/"
          >erlendweb</a></p>
      </div>
    </footer>
  </div>
</template>

<script>
export default {
  name: 'Footer',
}
</script>

<style scoped>
footer {
  background-color: var(--clr-primary-dark);
  background-image: linear-gradient(to right top, #0c181d, #0f1e24, #12232b, #152932, #182f3a);
  color: var(--clr-text-light);
  position: relative;
  isolation: isolate;
}

footer .social-links {
  list-style-type: none;
  padding: 1rem 0 0.5rem;

  display: flex;
  flex-direction: row;
  justify-content: center;
}

footer .social-links a {
  height: 50px;
  width: 50px;
  padding: 10px;
  margin: 0 1rem;
  font-size: 1.5rem;

  display: grid;
  place-items: center;
}

footer .copyright {
  background-color: var(--clr-primary-darker);
  text-align: center;
  padding: 1rem 0 1rem;
}

footer .copyright p {
  font-size: var(--fs-s);
  margin-bottom: 0;
}
</style>