<template>
  <div id="app">
    <div class="main">
      <router-view />
    </div>
    <Footer></Footer>
  </div>
</template>

<script>
import Footer from '@/components/Footer.vue'
import AppImg from "@/components/AppImg.vue";



export default {
  components: {
    Footer,
    AppImg
  }
}
</script>

<style scoped>

</style>
