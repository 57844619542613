<template>
  <div class="home">

    <div class="top-grid">

      <div class="top-grid-item">

        <div class="top-grid-caption">
          <h1 class="top-grid-title title title-xxl">haakon evjen<br>
            <span class="uppercase">tash</span>
          </h1>

          <ul class="top-grid-media">

            <li>
              <button
                class="btn btn-media"
                @click="scrollToAnchorPoint('musikk')"
              >Musikk</button>
            </li>

            <li>
              <button
                class="btn btn-media"
                @click="scrollToAnchorPoint('galleri')"
              >Galleri</button>
            </li>
          </ul>

        </div>

      </div>

      <div class="top-grid-item">

        <div class="top-grid-image">

          <AppImg
            class="fade-right"
            :src="`/images/top/evjen.jpg`"
            width="1080"
            height="1080"
            placeholder="/images/top/evjen-xs.jpg"
            alt="DJ evjen on the beat"
          />

        </div>

      </div>

    </div>

    <div
      class="scroll-wrapper"
      ref="musikk"
    >
      <musikk />
    </div>

    <div
      class="scroll-wrapper"
      ref="galleri"
    >
      <galleri />
    </div>

  </div>
</template>

<script>
import Musikk from '@/views/media/Musikk.vue'
import Galleri from '@/views/media/Galleri.vue'

import AppImg from "@/components/AppImg.vue";


export default {
  name: 'Home',
  components: {
    Musikk,
    Galleri,
    AppImg
  },

  data() {
    return {
    }
  },

  methods: {
    // Scroll section into view
    scrollToAnchorPoint(refName) {
      const el = this.$refs[refName]
      el.scrollIntoView({ behavior: 'smooth' })
    }
  },

}
</script>

<style scoped>
.top-grid {
  height: 100vh;
  margin-bottom: 1rem;
  overflow: hidden;
  isolation: isolate;

  display: grid;
  grid-template-columns: repeat(10, 1fr);
  grid-template-rows: repeat(10, 10vh);
}

.top-grid-item {
  position: relative;
}

.top-grid-item:nth-child(1) {
  z-index: 1;
  grid-column-start: 1;
  grid-column-end: 5;
  grid-row-start: 1;
  grid-row-end: 11;
}

.top-grid-item:nth-child(2) {
  z-index: 0;
  grid-column-start: 5;
  grid-column-end: 11;
  grid-row-start: 1;
  grid-row-end: 11;
}

.top-grid-caption {
  height: 100%;
  width: 100%;

  padding: max(2rem, 10%);

  text-align: right;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  position: relative;
  flex-direction: column;

  position: relative;
}

.top-grid-title {
  line-height: 0.9;
}

.top-grid-title span {
  font-family: var(--ff-accent);
  letter-spacing: 0;
  font-size: 200%;
  margin-right: -4px;
  /*background-image: linear-gradient(
    to right top,
    #d16ba5,
    #c777b9,
    #ba83ca,
    #aa8fd8,
    #9a9ae1,
    #8a9ee0,
    #7da1dd,
    #72a4d8,
    #67a0cc,
    #619cbe,
    #5d97b1,
    #5d92a4
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;*/
}

.top-grid-image {
  position: relative;
  height: 100%;
}

.top-grid-image img {
  object-fit: cover;
  height: 100%;
  width: 100%;
}

.top-grid-media {
  width: fit-content;
  list-style-type: none;
  display: flex;
  gap: 1rem;
}

.top-grid-media .btn-media {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  font-size: var(--fs-default);
  text-transform: uppercase;
  padding: 0.75em 1.75em;
}

@media (max-width: 1199.98px) {
  .top-grid-item:nth-child(1) {
    grid-column-start: 1;
    grid-column-end: 6;
  }

  .top-grid-item:nth-child(2) {
    grid-column-start: 6;
    grid-column-end: 11;
  }
}

@media (max-width: 991px) {
  .top-grid-media .btn-media {
    font-size: var(--fs-xs);
  }
  .top-grid-item {
    padding: 0;
  }
}
@media (max-width: 575px) {
  .top-grid-title {
    line-height: 1;
  }
  .top-grid-title span {
    margin-right: 0;
  }
  .top-grid-image {
    max-height: 100%;
  }

  .top-grid-caption {
    justify-content: center;
    align-items: center;
    text-align: center;
  }

  .top-grid-media {
    position: relative;
    left: auto;
  }

  .top-grid-item:nth-child(1) {
    grid-column-start: 1;
    grid-column-end: 11;
    grid-row-start: 1;
    grid-row-end: 6;
  }

  .top-grid-item:nth-child(2) {
    grid-column-start: 1;
    grid-column-end: 11;
    grid-row-start: 5;
    grid-row-end: 11;
  }
}
</style>
