<template>
  <div
    class="galleri"
    id="galleri"
  >

    <div class="grid-media">

      <div class="grid-media-item">

        <div class="grid-media-content">

          <span class="grid-media-tag">
            <a
              class="link"
              href="https://www.instagram.com/haakon_evjen/"
              target="_blank"
            >
              <span class="icon">
                <fa-icon :icon="['fab', 'instagram']" />
              </span>
              haakon_evjen
            </a>
          </span>

          <h2 class="title title-xl lowercase">Noen bilder</h2>

          <VuePictureSwipe
            :items="items"
            :options="{shareEl: false}"
          ></VuePictureSwipe>

        </div>

      </div>

      <div class="grid-media-item">

        <div class="grid-media-image">

          <AppImg
            class="fade-right"
            :src="`/images/media/vibe-2.jpg`"
            width="1080"
            height="1080"
            placeholder="/images/media/vibe-2-xs.jpg"
            alt="Chill vibe stue"
          />

        </div>
      </div>

    </div>

  </div>

</template>

<script>
import AppImg from "@/components/AppImg.vue";

// https://github.com/rap2hpoutre/vue-picture-swipe
import VuePictureSwipe from 'vue-picture-swipe';

export default {
  components: {
    VuePictureSwipe,
    AppImg
  },

  data() {
    return {
      items: [
        {
          src: require('@/images/gallery/evjen.jpg'),
          thumbnail: require('@/images/gallery/evjen-xs.jpg'),
          w: 1818,
          h: 1228,
          alt: ''
        },
        {
          src: require('@/images/gallery/sesh.jpg'),
          thumbnail: require('@/images/gallery/sesh-xs.jpg'),
          w: 1793,
          h: 2943,
          alt: ''
        },
        {
          src: require('@/images/gallery/vibe-1.jpg'),
          thumbnail: require('@/images/gallery/vibe-1-xs.jpg'),
          w: 2048,
          h: 1358,
          alt: ''
        },
        {
          src: require('@/images/gallery/vibe-2.jpg'),
          thumbnail: require('@/images/gallery/vibe-2-xs.jpg'),
          w: 2048,
          h: 1358,
          alt: ''
        },
        {
          src: require('@/images/gallery/nttår.jpg'),
          thumbnail: require('@/images/gallery/nttår-xs.jpg'),
          w: 2048,
          h: 1358,
          alt: ''
        },
        {
          src: require('@/images/gallery/evjenaldo.jpg'),
          thumbnail: require('@/images/gallery/evjenaldo-xs.jpg'),
          w: 2048,
          h: 1358,
          alt: ''
        },
      ]
    };
  }

};
</script>

<style scoped>
.grid-media-item:nth-child(1) {
  z-index: 1;
  grid-column-start: 1;
  grid-column-end: 6;
}

.grid-media-item:nth-child(2) {
  grid-column-start: 4;
  grid-column-end: 11;
}

@media (max-width: 1199.98px) {
  .grid-media-item {
    padding: 0;
  }
  .grid-media-item:nth-child(1) {
    grid-column-start: 1;
    grid-column-end: 8;
  }

  .grid-media-item:nth-child(2) {
    grid-column-start: 7;
    grid-column-end: 11;
  }
}

@media (max-width: 767px) {
  .grid-media-content {
    margin: 0;
  }

  .grid-media-image img {
    height: max(250px, 400px);
  }

  .grid-media-item:nth-child(1) {
    grid-column-start: 1;
    grid-column-end: 11;
    grid-row-start: 1;
    grid-row-end: auto;
  }

  .grid-media-item:nth-child(2) {
    grid-column-start: 1;
    grid-column-end: 11;
    grid-row-start: auto;
    grid-row-end: auto;
  }
}
</style>