/* import the fontawesome core */
import { library } from '@fortawesome/fontawesome-svg-core'

/* import specific icons */
// import { far } from '@fortawesome/free-regular-svg-icons'
import { faMusic, faImages } from '@fortawesome/free-solid-svg-icons'
import { faInstagram, faSoundcloud } from '@fortawesome/free-brands-svg-icons'


/* add icons to the library */
// library.add(fas, far, fab)
library.add(faInstagram, faSoundcloud, faMusic, faImages)