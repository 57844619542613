<template>
  <div
    class="musikk"
    id="musikk"
  >

    <div class="grid-media">

      <div class="grid-media-item">

        <div class="grid-media-image">

          <AppImg
            class="fade-left"
            :src="`/images/media/vibe-1.jpg`"
            width="1080"
            height="1080"
            placeholder="/images/media/vibe-1-xs.jpg"
            alt="Chill vibe stue"
          />

        </div>
      </div>

      <div class="grid-media-item">

        <div class="grid-media-content">
          <span class="grid-media-tag">
            <a
              class="link"
              href="https://soundcloud.com/dunright"
              target="_blank"
            >
              <span class="icon">
                <fa-icon :icon="['fab', 'soundcloud']" />
              </span>
              tash
            </a>
          </span>
          <h2 class="title title-xl lowercase">Et par tunes</h2>
          <ul class="media-content-links">
            <li>
              <iframe
                width="100%"
                height="250"
                scrolling="no"
                frameborder="no"
                allow="autoplay"
                src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1223348902&color=%2376a4b8&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true&visual=true"
              ></iframe>
              <div style="font-size: 10px; color: #cccccc;line-break: anywhere;word-break: normal;overflow: hidden;white-space: nowrap;text-overflow: ellipsis; font-family: Interstate,Lucida Grande,Lucida Sans Unicode,Lucida Sans,Garuda,Verdana,Tahoma,sans-serif;font-weight: 100;"><a
                  href="https://soundcloud.com/dunright"
                  title="Tash"
                  target="_blank"
                  style="color: #cccccc; text-decoration: none;"
                >Tash</a> · <a
                  href="https://soundcloud.com/dunright/mix-vol-2-french-cassette"
                  title="Mix Vol. 2 // French Cassette"
                  target="_blank"
                  style="color: #cccccc; text-decoration: none;"
                >Mix Vol. 2 // French Cassette</a></div>
            </li>
            <li><iframe
                width="100%"
                height="250"
                scrolling="no"
                frameborder="no"
                allow="autoplay"
                src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1159563493&color=%23ff5500&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true&visual=true"
              ></iframe>
              <div style="font-size: 10px; color: #cccccc;line-break: anywhere;word-break: normal;overflow: hidden;white-space: nowrap;text-overflow: ellipsis; font-family: Interstate,Lucida Grande,Lucida Sans Unicode,Lucida Sans,Garuda,Verdana,Tahoma,sans-serif;font-weight: 100;"><a
                  href="https://soundcloud.com/dunright"
                  title="Tash"
                  target="_blank"
                  style="color: #cccccc; text-decoration: none;"
                >Tash</a> · <a
                  href="https://soundcloud.com/dunright/mix-vol-1"
                  title="Mix Vol. 1"
                  target="_blank"
                  style="color: #cccccc; text-decoration: none;"
                >Mix Vol. 1</a></div>
            </li>
          </ul>

        </div>

      </div>

    </div>

  </div>
</template>

<script>
import AppImg from "@/components/AppImg.vue";

export default {
  name: "musikk",
  components: {
    AppImg
  }

}
</script>

<style scoped>
.grid-media-item:nth-child(1) {
  grid-column-start: 1;
  grid-column-end: 8;
}
.grid-media-item:nth-child(2) {
  z-index: 1;
  grid-column-start: 6;
  grid-column-end: 11;
}

@media (max-width: 1199.98px) {
  .grid-media-item {
    padding: 0;
  }
  .grid-media-item:nth-child(1) {
    grid-column-start: 1;
    grid-column-end: 7;
  }

  .grid-media-item:nth-child(2) {
    grid-column-start: 4;
    grid-column-end: 11;
  }
}

@media (max-width: 767px) {
  .grid-media-content {
    margin: 0;
  }

  .grid-media-image img {
    height: max(250px, 400px);
  }

  .grid-media-item:nth-child(1) {
    grid-column-start: 1;
    grid-column-end: 11;
    grid-row-start: auto;
    grid-row-end: auto;
  }

  .grid-media-item:nth-child(2) {
    grid-column-start: 1;
    grid-column-end: 11;
    grid-row-start: 1;
    grid-row-end: auto;
  }
}
</style>